import * as React from 'react';

interface IInputFieldProps {
	name: string;
	type: 'color' | 'range' | 'text' |'checkbox';
	min?: number;
	max?: number;
	step?: number;
	defaultValue?: string | number;
	handleChange: (target: any) => void;
	hideLabel?: boolean;
	value?: string | number;
	placeholder?: string | number;
	SetColorR?:string | number;
	ColorR?:string | number;
	
	

	
}


export const InputField = ({ColorR,SetColorR, name, type, handleChange, min, max, step, 
	defaultValue, hideLabel, value,placeholder }: IInputFieldProps) => {


	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' , }}>
			{!hideLabel && <label>{name}</label>}
			<input
				type={type}
				id={name}
				name={name}
				onChange={handleChange}
				min={min}
				max={max}
				step={step || 1}
				defaultValue={defaultValue}
				value={value}
				placeholder={ ' Enter : Text / URL / Phon /Social Media '}

			/>
		</div>
	);
}