import React, { useState } from 'react';
import { InputField } from './components/InputField';
import { QRCode } from 'react-qrcode-logo';

import { SelectField } from './components/SelectField';
import { ImageUploadField } from './components/ImageUploadField';
import { CheckboxField } from './components/CheckboxField';
import ReactJson from 'react-json-view';
import html2canvas from 'html2canvas';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import { type } from '@testing-library/user-event/dist/type';
import { time } from 'console';
import { color } from '@mui/system';
import { green, grey, red } from '@mui/material/colors';
import { alignProperty } from '@mui/material/styles/cssUtils';
import image1 from './imagess/image1.png';
import image2 from './imagess/image2.png';
import image3 from './imagess/image3.png';
import image4 from './imagess/image4.png';
import image5 from './imagess/image5.png';
import image6 from './imagess/image6.png';
import image7 from './imagess/image7.png';
import image8 from './imagess/image8.png';
import image9 from './imagess/image9.png';
import image10 from './imagess/image10.png';
import image11 from './imagess/image11.png';
import image12 from './imagess/image12.png';
import image13 from './imagess/image13.png';
import image14 from './imagess/image14.png';
import image15 from './imagess/image15.png';
import image18 from './imagess/image18.png';
import image17 from './imagess/image17.png';
import businessCard from './imagess/businessCard.svg';
import plus from './imagess/plus.png';
import menu2 from './imagess/menu2.svg';
import socialM from './imagess/socialM.svg';
import subtract from './imagess/subtract.png';
import bitcoin from './imagess/bitcoin.svg';
import gothub from './imagess/gothub.svg';
import logoqr from './imagess/logoqr.svg';
import logoqrBox from './imagess/logoqrBox.svg';
import instagram from './imagess/instagram.svg';
import Facbook from './imagess/facebook-svgrepo-com.svg';
import Youtube from './imagess/youtube-svgrepo-com.svg';
import Linkdin from './imagess/linkedin.svg';
import one from './imagess/one-svgrepo-com.svg';
import two from './imagess/two-svgrepo-com.svg';
import three  from './imagess/three-svgrepo-com.svg';
import four from './imagess/four-svgrepo-com.svg';
import facbook from './imagess/facebook22.svg';
import twitter from './imagess/twitter33.svg';
import youtube from './imagess/youtube22.svg';
import github from './imagess/gothub.svg';
import { url } from 'inspector';
import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow';
import { getValue } from '@testing-library/user-event/dist/utils';
import { isTemplateExpression, tokenToString } from 'typescript';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

import bloBB from './imagess/blob.svg';









const App: React.FC = () => {
	const [state, setState] = useState<{ [key: string]: any }>({});
    
	const handleChange = ({ target }: any) => {
		

		setState(prevState => ({ ...prevState, [target.name]: target.value }))
			
	}

	
	
	
  

	
	
		
	

	const handleDownload = () => {
		html2canvas(document.querySelector('#react-qrcode-logo') as any)
			.then(function (canvas) {
				const link = document.createElement('a');
				link.download = 'QR-dxbcode.png';
				link.href = canvas.toDataURL();
				link.click();
			});
	}

	const handleDownloadS = () => {
		html2canvas(document.querySelector('#react-qrcode-logo') as any)
			.then(function (canvas) {
				const link = document.createElement('a');
				link.download = 'QR-dxbcode.svg';
				link.href = canvas.toDataURL();
				link.click();
			});
	}

	const buildEyeRadiusInput = (id: string) => {
		return <InputField
			name={id}
			type='range'
			handleChange={handleChange}
			min={0}
			max={50}
			hideLabel
			defaultValue={(state as any)[id]}
		/>
	};



    const[selected,setselected]=useState(null)
	
	const toggle =  (i:any) =>{
          if(selected == i){

			return setselected(null)
		  }
		 
          setselected(i);

		  

	}
	

	


	
	

	
       const sys=[



	{ 
		Q:"ENTER CONTENT",
	    inputValuo:<InputField name='value' type='text' handleChange={handleChange} />,
	   /* selectValuo: <SelectField name='ecLevel'  options={['L', 'M', 'Q', 'H']} handleChange={handleChange} />,
    	checkbox:<CheckboxField	name='enableCORS' handleChange={handleChange}/>,
		inputSize:<InputField name='size' type='range'handleChange={handleChange}min={100}max={250}/>,
	inputquiteZone:<InputField name='quietZone'	type='range'handleChange={handleChange}	min={20}max={50}/>,
		inputBgcolor:<InputField name='bgColor'	type='color' defaultValue='#ffffff'handleChange={handleChange} />,
		inputFgcolor:<InputField name='fgColor'	type='color' defaultValue='#000000'	handleChange={handleChange}/>,*/
		icon:image1,icon1:image2,
		icon2:image3,icon3:image4,
		icon4:image5,icon5:image6,
		icon6:image7,icon7:image8,
		icon8:image9,icon9:image10,
		icon10:image11,icon11:image12,
		icon12:image13,icon13:image14,
		icon14:image15,icon15:image18,
		icon16:image17
	},

	{CO:'COLOR',
		Color_1:'body Color',Color_2: 'Dot Color',
	inputBgcolor:<InputField name='bgColor'	type='color' defaultValue='#FFFFFF' handleChange={handleChange}  /> ,
	inputFgcolor:<InputField name='fgColor'	type='color' defaultValue='#000000'	handleChange={handleChange}/>,
	H:'Right' , K:'Left', L:'Bottom ',
	

	name1:'Outer',eyecolor_0_outer:<InputField name='eyecolor_0_outer'type='color'defaultValue={state.fgColor ?? '#000000'}
	handleChange={handleChange}hideLabel={true}/>,
							
	name2:'Inner',eyecolor_0_inne:<InputField	name='eyecolor_0_inner'	type='color'defaultValue={state.fgColor ?? '#000000'}
									handleChange={handleChange}
									hideLabel={true}
								/>,
						
								name3:'Outer',eyecolor_1_outer:<InputField name='eyecolor_1_outer'type='color'defaultValue={state.fgColor ?? '#000000'}
									handleChange={handleChange}
									hideLabel={true}

							
								/>,
								
								name4:'Inner',eyecolor_1_inner:<InputField name='eyecolor_1_inner'type='color'defaultValue={state.fgColor ?? '#000000'}
									handleChange={handleChange}
									hideLabel={true}
								/>,
							
								name5:'Outer',eyecolor_2_oute:<InputField	name='eyecolor_2_outer'	type='color'defaultValue={state.fgColor ?? '#000000'}
									handleChange={handleChange}
									hideLabel={true}
								/>,
								
								name6:'Inner',eyecolor_2_inner:<InputField name='eyecolor_2_inner'type='color'defaultValue={state.fgColor ?? '#000000'}
									handleChange={handleChange}
									hideLabel={true}
								/>,


	},

	{ 

	 R:"ADD LOGO IMAGE",
	imageUpload:<ImageUploadField name='logoImage'handleChange={handleChange}/>,
	logowidth:<InputField name='logoWidth' type='range'	handleChange={handleChange}	min={20}max={250}/>,
	logoHeight:<InputField	name='logoHeight'type='range'handleChange={handleChange} min={20}max={250}/>,
    logoOpacity:<InputField	name='logoOpacity' type='range'	handleChange={handleChange}	min={0}	max={1}	step={0.1}/>,
	/*qrStyle:<SelectField name='qrStyle'	options={['squares', 'dots']} handleChange={handleChange}/>,*/
	removeQrCodeBehindLogo:<CheckboxField name='removeQrCodeBehindLogo'	handleChange={handleChange}	/>,
		
	},

    { J:"CUSTOMIZE DESIGN", 

	
								x:"",
		N:'Top Right',M:'Top Left',O:'Bottom Left',
		AN:'Outer',AM:'Inner',
       
		name:'outer',Eyeradius_0_outer_0:buildEyeRadiusInput('eyeradius_0_outer_0'),
		name11:'outer',Eyeradius_0_outer_1:buildEyeRadiusInput('eyeradius_0_outer_1'),
		name22:'outer',Eyeradius_0_outer_2:buildEyeRadiusInput('eyeradius_0_outer_2'),
		name10003:'outer',Eyeradius_0_outer_3:buildEyeRadiusInput('eyeradius_0_outer_3'),
		
		name44:'outer',Eyeradius_1_outer_0:buildEyeRadiusInput('eyeradius_1_outer_0'),
		name55:'outer',Eyeradius_2_outer_0:buildEyeRadiusInput('eyeradius_1_outer_1'),
		name66:'outer',Eyeradius_3_outer_0:buildEyeRadiusInput('eyeradius_1_outer_2'),
		name77:'outer',Eyeradius_4_outer_0:buildEyeRadiusInput('eyeradius_1_outer_3'),

		name88:'outer',Eyeradius_11_outer_0: buildEyeRadiusInput('eyeradius_2_outer_0'),
		name99:'outer',Eyeradius_12_outer_0:buildEyeRadiusInput('eyeradius_2_outer_1'),
		name111:'outer',Eyeradius_13_outer_0:buildEyeRadiusInput('eyeradius_2_outer_2'),
		name112:'outer',Eyeradius_14_outer_0:buildEyeRadiusInput('eyeradius_2_outer_3'),


		name113:'Inner',Eyeradius_0_Inner_0: buildEyeRadiusInput('eyeradius_0_inner_0'),
		name114:'Inner',Eyeradius_0_Inner_1:buildEyeRadiusInput('eyeradius_0_inner_1'),		
		name115:'Inner',Eyeradius_0_Inner_2:buildEyeRadiusInput('eyeradius_0_inner_2'),
		name116:'Inner',Eyeradius_0_Inner_3:buildEyeRadiusInput('eyeradius_0_inner_3'),

		name117:'Inner',Eyeradius_1_Inner_0:buildEyeRadiusInput('eyeradius_1_inner_0'),
		name118:'Inner',Eyeradius_1_Inner_1:buildEyeRadiusInput('eyeradius_1_inner_1'),
		name119:'Inner',Eyeradius_1_Inner_2:buildEyeRadiusInput('eyeradius_1_inner_2'),
		name120:'Inner',Eyeradius_1_Inner_3:buildEyeRadiusInput('eyeradius_1_inner_3'),

		name121:'Inner',Eyeradius_2_Inner_0:buildEyeRadiusInput('eyeradius_2_inner_0'),
		name122:'Inner',Eyeradius_2_Inner_1:buildEyeRadiusInput('eyeradius_2_inner_1'),
		name123:'Inner',Eyeradius_2_Inner_2:buildEyeRadiusInput('eyeradius_2_inner_2'),
		name124:'Inner',Eyeradius_2_Inner_3:buildEyeRadiusInput('eyeradius_2_inner_3'),
		
								
	},
	

	{QI:"OPTIONS",
	 selectValuo: <SelectField name='ecLevel'  options={['L', 'M', 'Q', 'H']} handleChange={handleChange} />,
	 qrStyle:<SelectField name='qrStyle'	options={['squares', 'dots']} handleChange={handleChange}/>,
	 checkbox:<CheckboxField name='enableCORS' handleChange={handleChange}/>,
	 


	},
	
	
   




	   ];

	
	 
	  

	return (



		<div className='app'>



<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,marginLeft:"0",height:20}}>
	
	</div> 

<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' , backgroundColor:/*'#f5f5f5'*/'#E8EEF2',color:'white'}}>
     

<div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding:5,marginLeft:10   }}>
	
	
	<img width={50}height={50} src={logoqr} ></img></div>

	

<div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap',marginLeft:5, color:'green',padding:5  }}>


<div><h2>  QR Code Generator  </h2></div>

<div><h4> CREATE YOUE QR CODE FOR FREE</h4></div>

</div>
<div id="topnav">
  <a id="active" href="#home">Home</a>
  <a href="#C4">About QR</a>
  <a href="#C5">FAQ</a>
  <a href="#C6">Contact</a>
 
</div>
</div>


<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,marginLeft:"0",height:20 }}><p></p></div>

{/*<img src={image1}/>*/}
{/*<img src={require('./imagess/image2.png').default} alt=""/>*/}


<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,margin:15, padding:15  ,justifyContent: 'center'}}>


                 <div className='accordian'>

				 


				 
				 
				 {sys.map((item, i) =>(
					<div className='item'>



						<div className='titel' onClick={() => toggle(i)}>


<h3>{item.Q}</h3>

<h3>{item.R }</h3>
<h3>{item.J }</h3>
<h3>{item.x }</h3>
<h3>{item.QI}</h3>
<h3>{item.CO}</h3>








                       
						<span >{selected == i ? <img src={subtract}  id='imagIcon'/>: <img src={plus}id='imagIcon'/>}</span>
						</div>
						


						
						
						<div className={selected == i ? 'contant show' : 'contant'}>
						{item.inputValuo}
						 
<table>
	<img src={item.icon1} alt=""/>
	<img src={item.icon6} alt=""/>
	<img src={item.icon8} alt=""/>
	<img src={item.icon4} alt=""/>
	<img src={item.icon5} alt=""/>
	<img src={item.icon16} alt=""/>
	<img src={item.icon10} alt=""/>
	<img src={item.icon12} alt=""/>
	<img src={item.icon13} alt=""/>
	<img src={item.icon14} alt=""/>
	<img src={item.icon15} alt=""/>
	
	

</table>







						
					
						{item.selectValuo}
						{item.qrStyle}	

						{<table>
						
						<td>{item.inputBgcolor}</td><td></td><td></td><td></td><td></td><td>{item.inputFgcolor}</td>
							
				 </table>}


			            {item.checkbox}
					
					

	
						
                                    
					 					 					  
				     {item.imageUpload}	
				     {item.logowidth} 
					 {item.logoHeight}
					 {item.logoOpacity}
                     
					 

                     {item.removeQrCodeBehindLogo}
                      
					 
					
		<table>
    
	<tr>


    <th>{item.H}</th>
	
	<th>{item.K}</th>
	
	
	<th>{item.L}</th>
	
</tr>

<tr>

    <th>{item.name1}</th>
	<th>{item.name1}</th>
	<th>{item.name1}</th>
</tr>
<tr>



	<td>{item.eyecolor_0_outer}</td> 

    <td>{item.eyecolor_1_outer}</td>
	
	<td>{item.eyecolor_2_oute}</td>
	
</tr>

<tr>

    <th>{item.name2}</th>
	<th>{item.name2}</th>
	<th>{item.name2}</th>
</tr>
<tr>

	  <td>{item.eyecolor_0_inne}</td>
	  <td>{item.eyecolor_1_inner}</td>
      <td>{item.eyecolor_2_inner}</td>
	 
</tr>
	  
   
	</table>


					
                   
					 
					 
					 
					 
		<table>	

			<tr>

				<th>{item.M}</th>
				<th>{item.N}</th>
				<th>{item.O}</th>

			</tr>
			

<tr>
<th>{item.AN}</th>
<th>{item.AN}</th>
<th>{item.AN}</th>
</tr>

<tr>
<td>{item.Eyeradius_0_outer_0}</td><td>{item.Eyeradius_1_outer_0}</td><td>{item.Eyeradius_11_outer_0}</td>
</tr>
<tr>
<td>{item.Eyeradius_0_outer_1}</td><td>{item.Eyeradius_2_outer_0}</td><td>{item.Eyeradius_12_outer_0}</td>
</tr>
<tr>
<td>{item.Eyeradius_0_outer_2}</td><td>{item.Eyeradius_3_outer_0}</td><td>{item.Eyeradius_13_outer_0}</td>
</tr>
<tr>
<td>{item.Eyeradius_0_outer_3}</td><td>{item.Eyeradius_4_outer_0}</td><td>{item.Eyeradius_14_outer_0}</td>
</tr>

<tr>
<th>{item.AM}</th>
<th>{item.AM}</th>
<th>{item.AM}</th>
</tr>
<tr>
<td>{item.Eyeradius_0_Inner_0}</td><td>{item.Eyeradius_1_Inner_0}</td><td>{item.Eyeradius_2_Inner_0}</td>
</tr>
<tr>
<td>{item.Eyeradius_0_Inner_1}</td><td>{item.Eyeradius_1_Inner_1}</td><td>{item.Eyeradius_2_Inner_1}</td>
</tr>
<tr>
<td>{item.Eyeradius_0_Inner_2}</td><td>{item.Eyeradius_1_Inner_2}</td><td>{item.Eyeradius_2_Inner_2}</td>
</tr>
<tr>
<td>{item.Eyeradius_0_Inner_3}</td><td>{item.Eyeradius_1_Inner_3}</td><td>{item.Eyeradius_2_Inner_3}</td>
</tr>







	
				
				   
</table>

					 </div>
						
						

					</div>
				 ))}




							 </div>
        

		
	     	
			    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
					 
			     
					
				</div>
				
				
                
				<div style={{	
					width: 400,
					height: 580,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: '1px solid #E8EEF2',
					boxShadow:'0 4px 12px 0 rgba(0,0,0,0.2)',
					borderTopRightRadius:10,
					borderBottomRightRadius:10,
					
					
					/*borderRadius: '10px',*/
					/*backgroundColor: '#f0ebe1',*/
			     	flexDirection: 'column',
					/*backgroundColor:'#b4c1cb',
					backgroundImage:'linear-gradient(135deg, rgba(255,255,255,.5) 30%, rgba(0,0,0,.1))'*/
					backgroundColor:/*'#E3E3E3'*/'white',
					/*boxShadow:'0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'*/
					
				

									
				}}>
					


					
					
					
					<QRCode 
					
					
					           value='dxbcode.com'
							   ecLevel='H'
							   qrStyle='dots'
							   
							   
							   
                    
					
					
						{...{ 
							...state,
							
							
							eyeRadius: [ // build eyeRadius manually
								{
									
									outer: [state.eyeradius_0_outer_0, state.eyeradius_0_outer_1, state.eyeradius_0_outer_2, state.eyeradius_0_outer_3],
									inner: [state.eyeradius_0_inner_0, state.eyeradius_0_inner_1, state.eyeradius_0_inner_2, state.eyeradius_0_inner_3],
								},
								{
									outer: [state.eyeradius_1_outer_0, state.eyeradius_1_outer_1, state.eyeradius_1_outer_2, state.eyeradius_1_outer_3],
									inner: [state.eyeradius_1_inner_0, state.eyeradius_1_inner_1, state.eyeradius_1_inner_2, state.eyeradius_1_inner_3],
								},
								{
									outer: [state.eyeradius_2_outer_0, state.eyeradius_2_outer_1, state.eyeradius_2_outer_2, state.eyeradius_2_outer_3],
									inner: [state.eyeradius_2_inner_0, state.eyeradius_2_inner_1, state.eyeradius_2_inner_2, state.eyeradius_2_inner_3],
								}
							],
							
							eyeColor: [ // build eyeColor manually
								{
									outer: state.eyecolor_0_outer ?? state.fgColor ?? '#000000',
									inner: state.eyecolor_0_inner ?? state.fgColor ?? '#000000'
								},
								{
									outer: state.eyecolor_1_outer ?? state.fgColor ?? '#000000',
									inner: state.eyecolor_1_inner ?? state.fgColor ?? '#000000'
								},
								{
									outer: state.eyecolor_2_outer ?? state.fgColor ?? '#000000',
									inner: state.eyecolor_2_inner ?? state.fgColor ?? '#000000'
								},
							]
							
							
						}}
						
					/>




<div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
<div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>

<InputField name='size' type='range'handleChange={handleChange}min={100}max={250}/>
<InputField name='quietZone'type='range'handleChange={handleChange}	min={20}max={50} />
</div>
					<button type='button' onClick={handleDownload}style={{ margin: '20px' }} className="button button2" >Download QR Code</button>
				
					</div>
				</div>
				</div>
			
			
			
				<div style={{ 
					display: 'flex', flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'center',
				 	alignItems: 'center',
					/*color:'#4CAF50',
					/*color:'white',*/
					color:'#56B48C',
					textAlign:'center',
					
					
					
					

					 
					 }}>

						<div style={{borderRadius: '10px',border: '1px solid #E8EEF2',display: 'flex',
						 flexDirection: 'column',flexWrap: 'wrap',justifyContent: 'center',
						 alignItems: 'center', margin:10, padding:10,boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)'}} >
					
					 <h3>Creat your custom QR with your LOGO for new Business Style</h3>
					
					</div>
					
					</div>
                       
					<div  style={{display: 'flex', flexDirection: 'row',
					flexWrap: 'wrap',justifyContent: 'center',alignItems: 'center',	color:'black',
					backgroundColor:'#E8EEF2'}}>

						
					
					<div style={{display: 'flex', flexDirection: 'column',flexWrap: 'wrap',
					justifyContent: 'center',alignItems: 'center',	color:'black', }}>

					
					<div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',margin:20,
					width:250,height:250,backgroundColor:'white',border: '1px solid #E8EEF2',borderRadius: '10px',padding:45}}>
					<img width={20}height={20} src={logoqrBox}></img>
					<img width={150}height={150} src={businessCard}></img>
					</div>
					<div style={{width:250,height:50,textAlign:'center',color:'grey'}}><h3>Business Card</h3></div>
					</div>


					
					<div  style={{display: 'flex', flexDirection: 'column',flexWrap: 'wrap',
					justifyContent: 'center',alignItems: 'center',	color:'black', margin:10}}>

					
					<div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',margin:20,
					width:250,height:250,backgroundColor:'white',border: '1px solid #E8EEF2',borderRadius: '10px',padding:45}}>
					<img width={20}height={20} src={logoqrBox}></img>
						<img width={150}height={150} src={menu2}></img></div>
					
					<div style={{width:250,height:50,textAlign:'center',color:'grey' }}>
						<h3>Resturant Menu</h3></div>
					

					</div>
					<div style={{display: 'flex', flexDirection: 'column',flexWrap: 'wrap',justifyContent: 'center',alignItems: 'center',	color:'black', margin:10}}>
					
					
					<div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',margin:20,
					width:250,height:250,backgroundColor:'white',border: '1px solid #E8EEF2',borderRadius: '10px',padding:45}}>
					<img width={20}height={20} src={logoqrBox}></img>
						<img width={150}height={150} src={bitcoin}></img>
						
						</div>
					
					  <div style={{width:250,height:50,textAlign:'center',color:'grey'}}><h3>Cryptocurrency</h3></div>

					</div>
					<div style={{display: 'flex', flexDirection: 'column',flexWrap: 'wrap',justifyContent: 'center',alignItems: 'center',	color:'black', margin:10}}>

					
					<div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',margin:20,
					width:250,height:250,backgroundColor:'#fff',border: '1px solid #E8EEF2',borderRadius: '10px',padding:45}}>
					<img width={20}height={20} src={logoqrBox}></img>
						<img width={150}height={150} src={socialM}></img>
						
						</div>
					<div style={{width:250,height:50,textAlign:'center',color:'grey'}}><h3>Social Media</h3></div>

					</div>

					
					
					
					
								
					
					</div>

		     {/*-------Artical------*/}


			 <div style={{display: 'flex', flexDirection: 'column',flexWrap: 'wrap',
			 justifyContent: 'center',alignItems: 'center',	color:'black', margin:10,backgroundColor:'white'}}>

                <div style={{backgroundColor:'whait'}}>
                <p style={{ color:'grey',fontSize:20 ,margin:15}}><h4>GET STARTED</h4></p>
				</div>	
				<br></br>
				<div style={{width:'', height:20,backgroundColor:'white',margin:10}}>
                <p><h1>Steps To Create The Firest QR</h1></p>
				</div>		
			 

                <br></br>
			 
			 <div  style={{display: 'flex', flexDirection: 'row',flexWrap: 'wrap',
			 justifyContent: 'center',alignItems: 'center',	color:'black', margin:10}}>

			 

				
				<div  style={{width:250, height:350,margin:10, textAlign:'justify'}}>
				<p><img width={50}height={50} src={one} ></img></p>
				
				<p style={{ color:'grey',fontSize:18}}>
				<h3 style={{ color:'green',}}>Set QR Content</h3>
				
				 <br></br>
				 
				Select a content type at the top for your QR code (URL, Text, Email...).
                After selecting your type you will see all available options. 
                Enter all fields that should appear when scanning your QR code. 
                Make sure everything you enter is correct because you can’t change the content once your QR code is printed.
                </p>
				
				</div>
				
				<div style={{width:250, height:350,margin:10,textAlign:'justify'}}>
                <p><img width={50}height={50} src={two} ></img></p>
				
				<p style={{ color:'grey',fontSize:18}}><h3 style={{ color:'green',}}>Customize Design</h3><br></br>
You want your QR code to look unique? Set a custom color and replace the standard shapes of your QR code.
 The corner elements and the body can be customized individually. Add a logo to your QR code. 
 Select it from the gallery or upload your own logo image. 
You can also start with one of the templates from the template gallery.</p>
				</div>
				<div style={{width:250, height:350,margin:10,textAlign:'justify'}}>
				<p style={{ color:'grey',fontSize:18	}}><img width={50}height={50} src={three} ></img>
				<br></br>
				
				 <p><h3 style={{ color:'green',}}>Generate QR Code</h3><br></br>
Set the pixel resolution of your QR code with the slider. Click the "Create QR Code"-button to see your qr code preview.
 Please make sure your QR code is working correctly by scanning the preview with your QR Code scanner. 
Use a high resolution setting if you want to get a png code with print quality.</p></p>
				</div>
				<div style={{width:250, height:360,margin:10,textAlign:'justify',}}>
                <p style={{ color:'grey',fontSize:18}}>
				<img width={50}height={50} src={four} ></img>
				<br></br>
				
				<h3 style={{ color:'green',}}>Download Image</h3><br></br>
Now you can download the image files for your QR code as .png or .svg, .pdf, .eps vector graphic.
 If you want a vector format with the complete design please choose .svg. SVG is working in software like Adobe Illustrator or Inkscape.
 The logo and design settings currently only work for .png and .svg files.
				</p>
				</div>
				</div>
				</div>
				<div style={{width:50, height:50}}>.</div>

				{/*-------benfit------*/}
				
				<div  style={{display: 'flex', flexDirection: 'column',color:'black' 
				  ,textAlign:'justify',justifyContent: 'center', alignItems: 'center',
				  flexWrap: 'wrap',backgroundColor:'#E8EEF2'}}>
              
                    <div id="C4"  style={{ color:'grey',fontSize:20 ,margin:15}} ><h5>ABOUT QR</h5></div>
					
					<div style={{ color:'black',fontSize:20,margin:15}}><h3>The Free QR Code Generator for High Quality QR Codes</h3></div>
					<div style={{ color:'black',fontSize:18,margin:15}}>QRCode DXBCODE is one of the most popular free online qr code generators with millions of already created QR codes.
						 The high resolution of the QR codes and the powerful design options make it one of the best free
						  QR code generators on the web that can be used for commercial and print purposes.
					</div>

					<div  style={{display: 'flex', flexDirection: 'row',color:'black' 
				  ,textAlign:'justify',justifyContent: 'center',
				  alignItems: 'center',flexWrap: 'wrap'}}>

                    <div style={{ margin:30,boxShadow:'0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'}}><img width={150}height={150} src={facbook}></img></div>
					<div style={{ margin:30,boxShadow:'0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'}}><img width={150}height={150} src={twitter}></img></div>
					<div style={{ margin:30,boxShadow:'0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'}}><img width={150}height={150} src={youtube}></img></div>
					

				  </div>

				</div>
				
				<div  style={{display: 'flex', flexDirection: 'row',flexWrap: 'wrap',justifyContent: 'center',
				alignItems: 'center',color:'black',	backgroundColor:'#E8EEF2'}}>
                   

                  <div  style={{display: 'flex', flexDirection: 'column',color:'black' 
				  ,width:500,height:490 ,textAlign:'justify'}}>
						
							<div style={{display: 'flex' ,margin:10,width:450, color:'black'}}>
                          <h3>  Endless lifetime with unlimited scans</h3><br></br>
							
                            QRCode DXBCODE also has no limitations. All generated QR codes will work forever,
                            do not expire and have no scanning limits like you see at other commercial QR code generators.
                            The created QR codes are static so the only limitation is that you can't edit the QR code again.
							</div>
							<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
							<h3>QR Codes with Logo</h3><br></br>
                            Put a custom brand on your QR code. With QRCode DXBCODE it is very simple and straightforward to add a logo to your QR Code. The QR codes are still readable. 
							Every QR code can have an error correction up to 30%. This means 30% of the QR code (excluding the corner elements) can be removed and the QR code is still working. 
							We can put a logo image on the QR code that covers up to 30%.
							</div>
							<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>Custom Design and Colors</h3><br></br>
                            Make your QR code look really unique with our design and color options. 
							You can customize the shape and form of the corner elements and the body of the QR code.
							You can also set your own colors for all QR code elements.
							Add a gradient color to the QR code body and make it really stand out.
							
							</div>

					</div>
					<div  style={{display: 'flex', flexDirection: 'column',color:'black' ,width:'',textAlign:'justify',justifyContent: 'center'}}>
						
							<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>High resolution QR Codes for Print</h3><br></br>
                            QRCode DXBCODE offers print quality QR codes with high resolutions. 
							When creating your QR code set the pixel size to the highest resolution to create .png files in print quality. 
							You can also download vector formats like .svg, .eps, .pdf for best possible quality.
							 We recommend the .svg format because it includes all design settings and gives you the perfect print format that can be used with most vector graphic software.
							</div>
							<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
							<h3>QR Codes with Logo</h3><br></br>
                            Put a custom brand on your QR code. With QRCode DXBCODE it is very simple and straightforward to add a logo to your QR Code. The QR codes are still readable. 
							Every QR code can have an error correction up to 30%. This means 30% of the QR code (excluding the corner elements) can be removed and the QR code is still working. 
							We can put a logo image on the QR code that covers up to 30%.
							</div>
							<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>Free for commercial usage</h3><br></br>
                            All generated QR Codes are 100% free and can be used for whatever you want.
							This includes all commercial purposes.
							</div>

					</div>
					
						    

				  
				  
                  
				






				</div>

			    {/*------- End of benfit------*/}
				{/*------- QUESTIONS------*/}

				<div style={{display: 'flex', flexDirection: 'column',color:'black' 
				  ,textAlign:'justify',justifyContent: 'center',
				  alignItems: 'center',flexWrap: 'wrap'}}>
                    <div style={{ color:'grey',fontSize:20 ,margin:15}} ></div>
                    <div id="C5" style={{ color:'grey',fontSize:20 ,margin:15}} ><h5>FREQUENTLY ASKED QUESTIONS</h5></div>
					<div style={{ color:'grey',fontSize:20 ,margin:15}} ><h5></h5></div>

				  </div>
				  
				
				<div style={{display: 'flex', flexDirection: 'row',color:'black' 
				  ,textAlign:'justify',justifyContent: 'center',
				  alignItems: 'center',flexWrap: 'wrap'}}>
					

                         

				<div style={{display: 'flex', flexDirection: 'column',color:'black' 
				  ,textAlign:'justify',justifyContent: 'center',
				  alignItems: 'center',flexWrap: 'wrap'}}>

                        <div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3 >What is a QR code?</h3><br></br>
                           QR code stands for Quick Response Code and is barcode type that was invented by Denso Wave in 1994. 
							Get more information at Wikipedia.
						</div>

						<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>Can I use the generated QR Codes for commercial purposes?</h3><br></br>
							Yes, all QR codes you created with this QR generator are free and can be used for whatever you want.
						</div>

						<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>Are the created qr codes expiring?</h3><br></br>
							They do not expire and will work forever! QR Codes created with QRCode are static and do not stop working after a certain time. 
							You just can’t edit the content of the QR Codes again.
						</div>

						<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>Is there a scan limit for the QR codes?</h3><br></br>
							There is no limit and the created QR code will work forever. Scan it is many times as you wish!
						</div>

                 </div>

				  <div style={{display: 'flex', flexDirection: 'column',color:'black' 
				  ,textAlign:'justify',justifyContent: 'center', alignItems: 'center',flexWrap: 'wrap'}}>

                        <div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>My QR code is not working, what can I do?</h3><br></br>
                            There are many reasons why a QR code is not working correctly. 
							At first check your entered data. Sometimes there are little typos in your URL that break your QR code.
							Some QR codes (like vCard) contain a lot of data. Try reducing the data you entered for your QR code when possible.
							This can make it easier for QR code scanner apps to read your code. Try to remove the logo in your QR code and check if this helps.
							Also make sure that there is enough contrast between the background and foreground of the QR code. The foreground should always be darker than the background. 
							Here is an article about reasons why your QR codes are not working.
						</div>
						<div style={{display: 'flex', color:'black' ,margin:10,width:450}}>
                            <h3>Does QRCode Monkey work in all browsers?</h3><br></br>
                            QRCode Monkey needs a modern HTML5 capable browser and is officially supporting
							 Chrome, Firefox, Safari, Edge and Internet Explorer 11.
						</div>
						



				  </div>
				
				</div>
				<div style={{display: 'flex', color:'black' ,margin:10,width:450,height:50}}>
                           
                           
						</div>
				{/*------- END QUESTIONS------*/}
				{/*------- footer------*/}




				<div  style={{display: 'flex', flexDirection: 'column',color:'#ffffff' 
				  ,textAlign:'justify',justifyContent: 'center', alignItems: 'center',
				  flexWrap: 'wrap',backgroundColor:'#26272B'}}>
					
					<div style={{display: 'flex'}}>
						  <p><h3>QR-Code-Creator </h3></p>
					</div>
					<div>	 
						  <p>QR-Code-Creator its free project help any one want to do QR Business</p>
						  <p>The QR code Library from Github - under MIT licence</p>
					</div>

					<div>	 
						  
					<p style={{marginTop:10}}><h4>Contact Us :</h4></p>
					</div>
					
				</div>
				
                <div  style={{display: 'flex', flexDirection: 'row',color:'#ffffff' 
				  ,textAlign:'center',justifyContent: 'center', alignItems: 'center',
				  flexWrap: 'wrap',backgroundColor:'#26272B',height:150}}>
                        
                    <div style={{margin:5}}>
					
						<div>
						<a href="https://github.com/yaserkya" target="_blank">
							<img width={40}height={40} src={github}></img></a></div>
						<div><a href="https://github.com/yaserkya" target="_blank">Github</a></div>
						
				    </div>
					<div style={{margin:5}}>
				        
				        <div><a href="https://www.linkedin.com/in/yaser-aglan-510797191/" target="_blank">
							<img width={40}height={40} src={Linkdin}></img></a>
						</div>
				        <div><a href="https://www.linkedin.com/in/yaser-aglan-510797191/" target="_blank">Linkdin</a></div>
				    </div>

				    <div style={{margin:5}}>
						
						<div>
						<a href="https://www.instagram.com/dxb.code/" target="_blank">
							<img width={40}height={40} src={instagram}></img></a></div>
						<div><a href="https://www.instagram.com/dxb.code/" target="_blank">Instgram</a></div>
					</div>	
              
                  

				</div>
				<div id="C6" style={{display: 'flex', flexDirection: 'row',color:'#ffffff' 
				  ,textAlign:'center',justifyContent: 'center', alignItems: 'center',
				  flexWrap: 'wrap',backgroundColor:'#1F2024'}}>
					<p>Design By - <a href="https://dxbcode.com" target="_blank">dxbcode.com</a></p>
					

				  </div>

				
                     


			



			 






			
			
			

			
			

			

			

			

			





			 







			

			
				
		</div>
		
		
	);
	

}




export default App;


function setColor(value: string): void {
	throw new Error('Function not implemented.');
}

